import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store.js'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import('@/views/dashboard/Index'),
      children: [
        {
          name: 'Login',
          path: '/login',
          component: () => import('@/views/dashboard/Login'),
        },
        {
          name: 'ShikakuList',
          path: '',
          component: () => import('@/views/dashboard/ShikakuList'),
        },
        {
          name: 'Timeline',
          path: '/timeline',
          component: () => import('@/views/dashboard/Timeline'),
        },
        /*
        {
          name: 'Calendar',
          path: '/calendar',
          component: () => import('@/views/dashboard/Calendar'),
        },
        {
          name: 'Progress',
          path: '/progress',
          component: () => import('@/views/dashboard/Progress'),
        },
        {
          name: 'Release',
          path: '/release',
          component: () => import('@/views/dashboard/Document'),
        },
        {
          name: 'Privacy',
          path: '/privacy',
          component: () => import('@/views/dashboard/Document'),
        },
        {
          name: 'Term',
          path: '/term',
          component: () => import('@/views/dashboard/Document'),
        },
        {
          name: 'About',
          path: '/about',
          component: () => import('@/views/dashboard/Document'),
        },
        */
      ],
    },
  ],
})

router.beforeEach((to, from, next) => {
  if (store.state.user.login !== true) {
    switch (to.name) {
      case 'Login':
        next()
        break
      case 'Privacy':
        next()
        break
      case 'Term':
        next()
        break
      case 'Release':
        next()
        break
      case 'About':
        next()
        break
      default:
        next({ path: '/login' })
        break
    }
  } else {
    next()
  }
})

export default router
