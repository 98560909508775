const API_KEY = 'AIzaSyAamtm5xke92Yjn3Qb3UEIygHE15qmGIiQ'
const AUTH_DOMAIN = 'shikaku-kanri.com'
const DATABASE_URL = 'https://shikaku-kanri-default-rtdb.asia-southeast1.firebasedatabase.app'
const PROJECT_ID = 'shikaku-kanri'
const STORAGE_BUCKET = 'shikaku-kanri.appspot.com'
const MESSAGEING_SENDER_ID = '34546649200'
const APP_ID = '1:34546649200:web:2dc1ced02fb84f80314cb5'
const MEASUREMENT_ID = 'G-0QE9TL1P42'

export {
  API_KEY,
  AUTH_DOMAIN,
  DATABASE_URL,
  PROJECT_ID,
  STORAGE_BUCKET,
  MESSAGEING_SENDER_ID,
  APP_ID,
  MEASUREMENT_ID,
}
