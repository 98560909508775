import firebase from 'firebase'
import store from '../store'
import moment from 'moment'

export function funcGetDataFromFirebase (uid) {
  return new Promise((resolve, reject) => {
    const colref = firebase.firestore().collection('users')
    colref.doc(uid).get()
      .then((doc) => {
        /* 既存ユーザの場合 */
        if (doc.exists) {
          resolve(doc.data())
        }
        /* 初回ユーザの場合 */
        resolve({})
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export function funcSetDataToFirebase () {
  const sendData = {
    uid: store.state.user.uid,
    shikakuList: store.state.user.shikakuList,
    studyList: store.state.user.studyList,
    kininaruList: store.state.user.kininaruList,
    taskList: store.state.user.taskList,
    darkMode: store.state.darkMode,
    lastUpdate: moment().format(),
  }
  const colref = firebase.firestore().collection('users')
  colref.doc(store.state.user.uid).set(sendData)
    .then(function (docRef) {
      return 0
    })
    .catch(function (error) {
      console.error('Failed to add document to firebase.', error)
      return 1
    })
}

export function funcLoggingToFirebase (logdata) {
  const sendData = {
    uid: store.state.user.uid,
    date: new Date(),
    log: logdata,
  }
  const colref = firebase.firestore().collection('logs')
  colref.doc().set(sendData)
    .then(function (docRef) {
      return 0
    })
    .catch(function (error) {
      console.error('Failed to add document to firebase.', error)
      return 1
    })
}
