import store from '../store'

export function funcStoredUserInformation (login, uid, displayName, photoURL, shikakuList, studyList, kininaruList, taskList, darkMode) {
  const userInformation = {
    login: login,
    uid: uid,
    displayName: displayName,
    photoURL: photoURL,
    shikakuList: shikakuList,
    studyList: studyList,
    kininaruList: kininaruList,
    taskList: taskList,
    darkMode: darkMode,
  }
  store.commit('SET_USER_INFO', userInformation)
}
