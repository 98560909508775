// =========================================================
// * Vuetify Material Dashboard - v2.1.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vuetify-material-dashboard
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/base'
import './plugins/chartist'
import './plugins/vee-validate'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import './registerServiceWorker'
import firebase from 'firebase'
import VueMeta from 'vue-meta'
import {
  funcGetDataFromFirebase,
  funcLoggingToFirebase,
} from './functions/fb-modules'
import { funcStoredUserInformation } from './functions/utils'
import {
  API_KEY,
  AUTH_DOMAIN,
  DATABASE_URL,
  PROJECT_ID,
  STORAGE_BUCKET,
  MESSAGEING_SENDER_ID,
  APP_ID,
  MEASUREMENT_ID,
} from './parameter'

Vue.config.productionTip = false

Vue.use(VueMeta)

const firebaseConfig = {
  apiKey: API_KEY,
  authDomain: AUTH_DOMAIN,
  databaseURL: DATABASE_URL,
  projectId: PROJECT_ID,
  storageBucket: STORAGE_BUCKET,
  messagingSenderId: MESSAGEING_SENDER_ID,
  appId: APP_ID,
  measurementId: MEASUREMENT_ID,
}

firebase.initializeApp(firebaseConfig)
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
firebase.analytics()

const app = () => {
  firebase.auth().onAuthStateChanged(user => {
    if (user) {
      funcGetDataFromFirebase(user.uid)
        .then((resultFb) => {
          const login = true
          const uid = user.uid
          const displayName = 'Googleアカウント'
          const photoURL = 'https://img.freepik.com/free-icon/search_318-265146.jpg'
          var shikakuList = []
          var studyList = []
          var kininaruList = []
          var taskList = []
          var darkMode = false
          if (Object.keys(resultFb).length) {
            if (Object.prototype.hasOwnProperty.call(resultFb, 'shikakuList')) {
              shikakuList = resultFb.shikakuList
            }
            if (Object.prototype.hasOwnProperty.call(resultFb, 'studyList')) {
              studyList = resultFb.studyList
            }
            if (Object.prototype.hasOwnProperty.call(resultFb, 'kininaruList')) {
              kininaruList = resultFb.kininaruList
            }
            if (Object.prototype.hasOwnProperty.call(resultFb, 'taskList')) {
              taskList = resultFb.taskList
            }
            if (Object.prototype.hasOwnProperty.call(resultFb, 'darkMode')) {
              darkMode = resultFb.darkMode
            }
          }
          funcStoredUserInformation(login, uid, displayName, photoURL, shikakuList, studyList, kininaruList, taskList, darkMode)
          /*
            アプリ起動時(すなわちパスが/loginの状態)に
            既にGoogleアカウントでログインの済みユーザあれば
            資格一覧ページに遷移する
          */
          if (router.currentRoute.path === '/login') {
            router.push({ path: '/' })
          }
          funcLoggingToFirebase('Googleアカウントでログインしました。')
        }).catch((error) => {
          console.error('Failed to get data of login users from firebase.', error)
        })
    } else {
      console.log('User not authenticated with Firebase.')
    }
  })
  /* eslint-disable */
  new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: h => h(App),
  }).$mount('#app')
}

app()


