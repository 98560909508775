import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: {
      login: false,
      uid: '',
      displayName: '',
      photoURL: '',
      shikakuList: [],
      studyList: [],
      kininaruList: [],
      taskList: [],
    },
    drawer: null,
    darkMode: null,
    barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
    barImage: 'https://demos.creative-tim.com/material-dashboard/assets/img/sidebar-1.jpg',
  },
  getters: {
    getUserName: state => {
      return state.userName
    },
    getLoginFlag: state => {
      return state.loginFlag
    },
  },
  mutations: {
    SET_USER_INFO (state, payload) {
      state.user.login = payload.login
      state.user.uid = payload.uid
      state.user.displayName = payload.displayName
      state.user.photoURL = payload.photoURL
      state.user.shikakuList = payload.shikakuList
      state.user.studyList = payload.studyList
      state.user.kininaruList = payload.kininaruList
      state.user.taskList = payload.taskList
      state.darkMode = payload.darkMode
    },
    UPDATE_STORE_SHIKAKULIST (state, payload) {
      state.user.shikakuList = payload.shikakuList
    },
    UPDATE_STORE_STUDYLIST (state, payload) {
      state.user.studyList = payload.studyList
    },
    UPDATE_STORE_KININARULIST (state, payload) {
      state.user.kininaruList = payload.kininaruList
    },
    UPDATE_STORE_TASKLIST (state, payload) {
      state.user.taskList = payload.taskList
    },
    SET_BAR_IMAGE (state, payload) {
      state.barImage = payload
    },
    SET_DRAWER (state, payload) {
      state.drawer = payload
    },
    SET_DARK_MODE (state, payload) {
      state.darkMode = payload
    },
  },
  actions: {
  },
  plugins: [createPersistedState({
    key: 'shikaku-kanri',
    paths: [
      'user',
      'drawer',
      'darkMode',
    ],
    storage: window.sessionStorage,
  })],
})
