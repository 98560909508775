<template>
  <router-view />
</template>

<script>
  import VueCookies from 'vue-cookies'
  import versionData from '../version.md'
  import Vue from 'vue'
  Vue.use(VueCookies)
  export default {
    name: 'App',
    metaInfo: {
      title: '資格管理アプリ | 取得済み・勉強中の資格をラクラク管理',
    },
    created () {
      this.$vuetify.theme.dark = this.$store.state.darkMode
      this.reloadIfUpdate()
    },
    methods: {
      reloadIfUpdate () {
        var version = versionData
        console.log('[WEB APP VERSION] ' + version)
        var versionCookies = this.$cookies.get('sub.w2or3w.work.version')
        console.log('[LOCAL VERSION] ' + versionCookies)
        // this.$cookies.set('sub.w2or3w.work.version', version, { expires: 10 })
        this.$cookies.set('sub.w2or3w.work.version', version)
        if (version !== versionCookies) {
          /*
          window.navigator.serviceWorker.getRegistrations()
            .then(registrations => {
              for (var registration of registrations) {
                registration.unregister()
              }
            })
          */
          window.location.reload(true)
          console.log('Cleared cached data as there is update.')
        } else {
          console.log('Use cached data.')
        }
      },
    },
  }
</script>
